import React, { useState } from 'react';
import {Row, Col } from 'reactstrap';
import GoogleDevicesBaseTable from '../../components/tables/GoogleDevicesBaseTable/GoogleDevicesBaseTable';
import Button from '../../components/Button/Button';

const GoogleDevices = () => {
	const [needToUpdate, setNeedToUpdate] = useState(true);
	
	return (
		<div className="container-fluid content settings extension">
			<Row className="page-title align-items-center">
				<Col sm={6}>
					<div className="page-title-box">
						<h2>
							Managed Devices 
							<Button
								size="small"
								aria-label="refresh"
								className="button-default-icon button-refresh ml-2"
								onClick={() => setNeedToUpdate(true)}
								generalType="default"
							>
								<i className="mdi mdi-refresh" />
							</Button>
						</h2>
					</div>
				</Col>
			</Row>

			<div className="page-block page-block_border">
        <GoogleDevicesBaseTable
					needToUpdate={needToUpdate}
					setNeedToUpdate={setNeedToUpdate}
				/>
      </div>
		</div>
	);
};

export default GoogleDevices;
