import { Button } from 'reactstrap'
import announcements from './announcements.module.scss'
import { WEB_SOCKET_EVENTS } from '../../constants/websocket'

export const notificationTypes = {
  ispOutage: 'Isp outage',
  lowBandwidth: 'Low bandwidth',
  unreliableConnection: 'Unreliable Connection',
  poorInternetQuality: 'Poor internet quality',
  insufficientBandwidth: 'Insufficient bandwidth',
  unavailableApplication: 'Unavailable application',
  widespreadAppOutage: 'Widespread app outage',
  reportedIssue: 'Reported issue'
}

const issuesTabByName = {
  network: '1',
  device: '2',
  application: '3',
  reported: '4',
}

export const renderNotification = (data, onMarkAsReadClick) => {
  const notifications = data.length > 0
    ? data.map(notification => {
    const {
      category,
      wasRead,
      title,
      content,
      createdAt,
      type,
      id,
      issueId = null
    } = notification;
    const isIssue = category === 'issue';
    const notificationIcon = isIssue
      ? `mdi-alert-circle ${announcements.colorRed}`
      : `mdi-information ${announcements.colorBlue}`;
    const linkClassName = `${announcements.dropdownItem} ${wasRead ? announcements.wasRead : ''}`;

    return (
      <div
        className={linkClassName}
        key={id}
        onClick={isIssue ? () => null : () => onMarkAsReadClick(id)}
      >
        <span className={announcements.dropdownItemIcon}>
          <i className={`mdi ${notificationIcon} font-size-17 align-middle mr-1`} />
        </span>
        <span className={announcements.dropdownItemInfo}>
          <span className={announcements.dropdownItemTitle}>{title}</span>
          <span className={announcements.dropdownItemContent}>{content}</span>
          <span className={announcements.dropdownItemDate}>{createdAt} | {notificationTypes?.[type]}</span>
        </span>
        {isIssue && (
          <span>
            <Button
              onClick={() => onMarkAsReadClick(id, `/admin/issues?tab=${issuesTabByName[type]}&id=${issueId}`)}
              color="link"
              className="button button-default"
            >
              Fix now
            </Button>
          </span>
        )}
      </div>
    );
  })
  : <div className='pt-1 text-center'>No notifications yet</div>
  return notifications
}

export const subscribeAdminEventArr = [
  WEB_SOCKET_EVENTS.NEW_ISSUE_RECORDER,
  WEB_SOCKET_EVENTS.NEW_EXTENSION_INSTALL_RECORDED,
  WEB_SOCKET_EVENTS.NEW_ANNOUNCEMENT_RECORDED,
  WEB_SOCKET_EVENTS.KNOWLEDGE_STATUS_UPDATED,
];