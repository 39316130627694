import { toastr } from "react-redux-toastr"
import apiClient from '../../gears/apiClient';

export const getCredentials = async (setConfig, setIsLoading) => {
  setIsLoading(true);
  try {
    const response = await apiClient.get('/config/my-google-config');
    setConfig(response);
  } catch(e) {
    toastr.error('No credentials found.')
  }
  setIsLoading(false);
}

export const addCredentials = async (payload, companyDetails, setIsLoading) => {
  setIsLoading(true);
  try {
    await apiClient.post('/config/upload-google-config', payload);
    companyDetails.has_google_config = true;
    
    toastr.success('Credentials uploaded!')
  } catch(e) {
    toastr.error('No credentials found.')
  }
  setIsLoading(false);
}

export const removeCredentials = async (id, setConfig, companyDetails, setIsLoading) => {
  setIsLoading(true);
  try {
    await apiClient.delete(`/config/google-config/${id}`);
    companyDetails.has_google_config = false;
    setConfig(null);

    toastr.success('Credentials removed!')
  } catch(e) {
    toastr.error(e?.error)
  }
  setIsLoading(false);
}