import React, { useCallback } from 'react';
import Button from "../../Button/Button";

const AiKnowledgeBaseTableColumns = ({ onModalOpen }) => {
	const truncateText = useCallback((text, maxLen) => {
		if (!text || text.length < maxLen) {
			return text;
		}
	
		return text.slice(0, maxLen) + '...';
	}, []);

	const getStatusTooltipText = useCallback((value) => {
		switch (value) {
			case 'uploaded':
				return 'The file has been added to the system but not yet processed.';
			
			case 'processing':
				return 'The file is currently being analyzed or parsed.';

			case 'extracted':
				return 'Data has been successfully extracted from the file.';

			case 'failed':
				return 'The processing or extraction failed due to an error.';

			default:
				return null;
		}
	}, []);

	const getTooltipProps = useCallback((value) => ({
		"data-tooltip-id": "table-tooltip",
		"data-tooltip-content": value,
		"data-tooltip-place": "top",
	}), []);

	const columns = [
		{
			Header: 'Name',
			accessor: 'fileName',
			Cell: ({value, row: { original }}) => (
				<Button onClick={() => onModalOpen(original.id, 'preview')} { ...getTooltipProps(value)} className="p-0">
					{truncateText(value, 40)}
				</Button>
			),
		},
		{
			Header: 'Status',
			accessor: 'status',
			Cell: ({ value }) => (
				<span { ...getTooltipProps(getStatusTooltipText(value))}>
					{value}
				</span>
			),
		},
		{
			Header: 'Description',
			accessor: 'description',
			Cell: ({ value }) => (
				<span { ...getTooltipProps(truncateText(value, 150))}>
					{truncateText(value, 50)}
				</span>
			),
		},
		{
			Header: 'Created By',
			accessor: 'addedBy',
		},
    {
			Header: 'Created At',
			accessor: 'createdAt',
		},
		{
			Header: 'Actions',
			accessor: 'id',
			Cell: ({value}) => (
				<div className="actions" style={{display: 'inline-flex'}}>
					<Button
						generalType="default"
						type="button"
						onClick={() => onModalOpen(value, 'edit')}
						title="Edit"
					>
						<i className="mdi mdi-pencil" />
					</Button>
					<Button
						generalType="default"
						className="ml-2"
						type="button"
						onClick={() => onModalOpen(value, 'delete')}
						title="Delete"
					>
						<i className="mdi mdi-delete" />
					</Button>
				</div>
			),
		},
	];
	
	return columns;
};

export default AiKnowledgeBaseTableColumns;
