import React, {useEffect, useState} from 'react';
import ReactTable from '../../ReactTable';
import {toastr} from "react-redux-toastr";
import * as PropTypes from "prop-types";
import AiKnowledgeBaseTableColumns from "./AiKnowledgeBaseTableColumns";
import { deleteKnowledge, fetchAiKnowledge } from '../../../pages/AiKnowledgeBase/aiKnowledgeBaseHelper';
import ConfirmationModal from '../../ConfirmationModal';
import ModalComponent from '../../modal/ModalComponent';
import FilePreview from '../../FilePreview';
import CreateNewEntryForm from '../../forms/CreateNewEntryForm';
import { Tooltip } from 'react-tooltip';

const AiKnowledgeBaseTable = ({
		needToUpdate,
		setNeedToUpdate,
  }) => {
	const [aiKnowledgeData, setAiKnowledgeData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedKnowledge, setSelectedKnowledge] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedKnowledgeData, setSelectedKnowledgeData] = useState({});

	useEffect(() => {
		if(needToUpdate) {
			fetchAiKnowledge(setAiKnowledgeData, setIsLoading);
			setNeedToUpdate(false);
		}
	}, [needToUpdate])
	
	const toggleModal = () => setIsModalOpen(!isModalOpen);
	
	const onModalOpen = (value, content) => {
		setSelectedKnowledge(value);
		if(content === 'delete') {
			setIsConfirmModalOpen(true);
		}
		if(content === 'preview') {
			setIsPreviewModalOpen(true);
		}
		if(content === 'edit') {
			setSelectedKnowledgeData(aiKnowledgeData.find(knowledge => knowledge.id === value));
			setIsEditModalOpen(true);
		}
	};
	
	const onSort = (e) => console.log(e);

	const onRejectButtonClick = () => {
		setIsConfirmModalOpen(false);
		setSelectedKnowledge(null);
	}

	const onConfirmButtonClick = () => {
		deleteKnowledge(
			selectedKnowledge,
			aiKnowledgeData,
			setAiKnowledgeData,
			setIsLoading,
			onRejectButtonClick
		)
	}
	
	return (
		<>
			<ReactTable
				columns={AiKnowledgeBaseTableColumns({ onModalOpen })}
				data={aiKnowledgeData}
				onSortChange={onSort}
				loading={isLoading}
				manualSortBy
				disableMultiSort
				disableSortBy={true}
			/>
			<Tooltip id="table-tooltip" />

			{isEditModalOpen && (
				<ModalComponent
				isModalOpen={isEditModalOpen}
				modalHeaderTitle="Edit"
				toggleModal={() => setIsEditModalOpen(!isEditModalOpen)}
				isModalFooter={false}
			>
				<CreateNewEntryForm
					entryData={selectedKnowledgeData}
					closeModal={() => setIsEditModalOpen(!isEditModalOpen)}
					updateData={() => setNeedToUpdate(true)}
				/>
			</ModalComponent>
			)}

			{isConfirmModalOpen && (
				<ConfirmationModal
					isOpen={isConfirmModalOpen}
					confirm={onConfirmButtonClick}
					confirmLabel="Delete"
					reject={onRejectButtonClick}
					rejectLabel="Cancel"
					bodyText="Are you sure you want to delete?"
				/>
			)}

			{isPreviewModalOpen && (
				<ModalComponent
					isModalOpen={isPreviewModalOpen}
					modalHeaderTitle="Preview file"
					toggleModal={() => setIsPreviewModalOpen(!isPreviewModalOpen)}
					isModalFooter={false}
					customClass="fullHeight"
				>
					<FilePreview id={selectedKnowledge} />
				</ModalComponent>
			)}
		</>
	);
};

AiKnowledgeBaseTable.propTypes = {
	needToUpdate: PropTypes.bool,
	setNeedToUpdate: PropTypes.func,
};

export default AiKnowledgeBaseTable;
