import React from 'react';
import { CustomInput, Input, Label } from 'reactstrap';

export const UploadModalBody = (handleEmailChange, handleFileChange) => {
  return (
    <div style={{ marginBottom: '30px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <div style={{ color: '#6e6e6e' }}>
        Please ensure the email is valid and the file is in JSON format!
        <hr style={{ margin: '10px 15px' }}/>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Label size='14px'> <b> Super Admin Email</b> </Label>
        <Input
          id="email"
          type="email"
          name="email"
          onChange={handleEmailChange}
        />{' '}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Label> <b> Credentials </b></Label>
        <CustomInput
          id="file"
          name="file"
          type="file"
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
}
