import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import GoogleCredentials from '../../components/GoogleCredentials/GoogleCredentials';
import { addCredentials, getCredentials, removeCredentials } from './helper';
import ActionModal from '../../components/ActionModal';
import Loader from '../../components/Loader';
import { UploadModalBody } from './modal';

function GoogleConfig() {
	const [config, setConfig] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const { companyDetails } = useSelector((state) => state.Company);

	const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
	const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);

	const [newEmail, setNewEmail] = useState('');
	const [emailHasErrors, setEmailHasErrors] = useState(true);

	const [newFile, setNewFile] = useState(null);
	const [fileHasErrors, setFileHasErrors] = useState(true);

	const [uploadDisabled, setUploadDisabled] = useState(true);
	const hasCredentials = !!config?.id;

	useEffect(() => {
		getCredentials(setConfig, setIsLoading);
	}, []);

	useEffect(() => {
		setUploadDisabled(emailHasErrors || fileHasErrors);
	}, [emailHasErrors, fileHasErrors])

	const upload = {
		handleAddButtonClick: () => {
			setConfirmModalOpen(true);
		},
		onModalCancelClick: () => {
			setConfirmModalOpen(false);
		},
		handleEmailChange: (e) => {
			const inputEmail = e.target.value;
			const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail);

			setNewEmail(isValidEmail ? inputEmail : null);
			setEmailHasErrors(!isValidEmail);
		},
		handleFileChange: (e) => {
			const inputFile = e.target.files[0];
			const isValidJsonFile = inputFile?.type === 'application/json';

			setNewFile(isValidJsonFile ? inputFile : null);
			setFileHasErrors(!isValidJsonFile);
		},
		onModalConfirmClick: async () => {
			const formData = new FormData();
	
			formData.append('file', newFile);
			formData.append('subject', newEmail);
	
			await addCredentials(formData, companyDetails, setIsLoading);
			await getCredentials(setConfig, setIsLoading);
	
			setConfirmModalOpen(false);
		},
	}

	const remove = {
		handleRemoveButtonClick: () => {
			setRemoveModalOpen(true);
		},
		onModalCancelClick: () => {
			setRemoveModalOpen(false);
		},
		onModalConfirmClick: async () => {
			await removeCredentials(config?.id, setConfig, companyDetails, setIsLoading);
	
			setRemoveModalOpen(false);
		},
	}
	
	return (
		<div className="container-fluid content settings extension">
			<Row className="page-title align-items-center">
				<Col sm={6}>
					<div className="page-title-box">
						<h2>Google Credentials</h2>
					</div>
				</Col>
			</Row>
			<div className="settings-customization recent-tests-card page-block page-block_border">
				{ isLoading && <Loader /> }
				{ !hasCredentials &&
					<div className="mb-4">
						Missing credentials. Click on the below button to set them up.
					</div>
				}
				{ hasCredentials &&
					<GoogleCredentials config={config}/>
				}
				{ !isLoading && !hasCredentials &&
					<Button
						className="button button-default"
						color="primary"
						onClick={() => upload.handleAddButtonClick()}
						>
						Add Credentials
					</Button>
				}
				{ !isLoading && hasCredentials &&
					<Button
						className="button button-default"
						color="primary"
						onClick={() => remove.handleRemoveButtonClick()}
					>
						Remove Credentials
					</Button>
				}
				{isConfirmModalOpen && (
					<ActionModal
						isOpen={isConfirmModalOpen}
						confirm={upload.onModalConfirmClick}
						confirmDisabled={uploadDisabled}
						confirmLabel="Upload"
						reject={upload.onModalCancelClick}
						rejectLabel="Cancel"
						headerText="Upload New Credentials"
					>
						{ UploadModalBody(upload.handleEmailChange, upload.handleFileChange, emailHasErrors, fileHasErrors) }
					</ActionModal>
				)}
				{isRemoveModalOpen && (
					<ActionModal
						isOpen={isRemoveModalOpen}
						confirm={remove.onModalConfirmClick}
						confirmLabel="Remove"
						reject={remove.onModalCancelClick}
						rejectLabel="Cancel"
						headerText="Remove Credentials"
					>
						Removing these credentials will prevent you from viewing and managing devices.
						Are you sure you want to continue?
					</ActionModal>
				)}
			</div>
		</div>
	);
}

export default GoogleConfig;
