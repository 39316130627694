import React from 'react';
import './google-credentials.scss';

function GoogleCredentialRow({ title, value }) {	
	return (
		<div className="gcredentials-row">
			<p className="gcredentials-title">{title}:</p>
			{value && <p className="gcredentials-value">{value}</p>}
		</div>
	);
}

export default GoogleCredentialRow;
