import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

export const ActionModalBody = ({
	action,
	setModalHasError,
	isCustomReboot,
	setIsCustomReboot,
	rebootDelay,
	setRebootDelay
}) => {
	const validateAndSetRebootDelay = (value) => {
		setModalHasError(value < 0 || value > 5);
		setRebootDelay(value);
	}

	switch (action) {
		case 'disable':
			return (
				<>
					<div className='mt-2'>
						When a Chrome device is disabled, it presents a locked screen which blocks any
						use of the device. It will still remain enrolled in your domain while disabled.
					</div>
					<div className='mt-2'>
						You can re-enable a disabled device at any time from your device list.
						After re-enabling the device, your users will be able to use it again
						in its normal managed state.
					</div>
				</>
			);

		case 'enable':
			return (
				<div className='mt-2'>
					Enabling this Chromebook will restore its functionality, allowing users to sign in and access
					their data. Any previous restrictions will be lifted, and the device will operate as normal.
					If this device was previously disabled for security reasons, ensure that it is safe to
					re-enable before proceeding. 
				</div>
			);

		case 'reboot':
			return (
				<>
					<div className='mt-2'>
						Rebooting this Chromebook will restart the device, clearing temporary issues and
						refreshing its system. All unsaved work will be lost, so please ensure any important
						data is saved before proceeding. This action will not affect the device's settings or user data. 
					</div>
					<div className='mt-4'>
						<b> Select the reboot delay: </b>
						<FormGroup className="form-group_radio">
							<Label check>
								Default (in 5 minutes)
								<Input
									type="radio"
									name="reboot"
									value="default"
									onChange={() => setIsCustomReboot(false)}
								/>{' '}
								<span />
							</Label>
							<Label check>
								Custom
								<Input
									type="radio"
									name="reboot"
									value="custom"
									onChange={() => setIsCustomReboot(true)}
								/>{' '}
								<span />
							</Label>
						</FormGroup>
						{ isCustomReboot && <FormGroup>
							<Label>
								<b> Reboot custom time (minutes): </b>
								<Input
									type="number"
									name="rebootDelay"
									onChange={(e) => validateAndSetRebootDelay(e.target.value)}
									invalid={rebootDelay < 0 || rebootDelay > 5}
								/>{' '}
							</Label>
						</FormGroup> }
					</div>
				</>
			);

		case 'wipe':
			return (
				<div className='mt-2'>
					This action will erase all user accounts, settings, and locally stored files from the device,
					restoring it to its default state. Any data that has not been backed up will be permanently lost.
					Please ensure you have saved important information before proceeding. 
				</div>
			);

		case 'powerwash':
			return (
				<div className='mt-2'>
					Pressing "Powerwash" will restore this Chromebook to its factory settings, removing all local data,
					user accounts, apps, and settings. Any files or data that are not synced to the cloud will be
					permanently lost. This action will not affect data stored in your Google account or cloud services. 
				</div>
			);

		default:
			return null;
	}
}
