import React from 'react';
import GoogleCredentialRow from "./GoogleCredentialRow";
import './google-credentials.scss';

function GoogleCredentials({ config }) {
	return (
		<div className="align-items-center">
			<div className="gcredentials-wrapper">
				<GoogleCredentialRow title="Admin Email" value={config?.adminEmail}/>
				<GoogleCredentialRow title="Service Email" value={config?.clientEmail}/>
				<GoogleCredentialRow title="Project" value={config?.project}/>
			</div>
		</div>
	);
}

export default GoogleCredentials;
